import './App.css';

function App() {
  return (
    <section>
        <article className="App" style={{maxWidth: 370, width: '100%'}}>
          <img src="imgs/me.jpg" alt="me" height="130" width="130" style={{borderRadius: "50%"}}></img>
          <h1>About</h1>
            <p>Currently living in London and working as Data Engineer at <a href="https://www.checkout.com">Checkout</a>.</p>
            <p>I was born in the Philippines but mostly grew up in Ireland. I studied computer science at <a href="https://www.wit.ie/">WIT</a> and have worked at <a href="https://www.distilled.ie/">Distilled</a> and <a href="https://www.ipsos.com">Ipsos</a>.</p>
            <p>Find me at <a href="https://twitter.com/johnclaro_">twitter</a>, <a href="https://github.com/johnclaro">github</a>, or <a href="https://linkedin.com/in/johnclaro">linkedin</a>.</p>
        </article>
    </section>
  );
}

export default App;
